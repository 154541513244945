<template>
  <v-container
    class="text-center py-1 font-size-14"
    :class="{ 'font-size-12': $vuetify.breakpoint.xsOnly }"
  >
    <div class="text--secondary">
      &copy; {{ $utils.dayjs().format("YYYY") }} Payfoot |
      <router-link :to="{ name: 'privacyPolicy' }" class="btn-basic">
        Privacy policy
      </router-link>
    </div>
  </v-container>
</template>

<script>
export default {};
</script>

<style></style>
