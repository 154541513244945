<template>
  <v-app
    class="default-layout overflow-hidden"
    :class="{ 'has-background': $route.meta.background !== false }"
  >
    <v-container class="d-flex flex-column flex-grow-1">
      <header class="pt-10">
        <router-link :to="{ name: 'app' }">
          <v-img
            :src="require('@/assets/logo/logo.png')"
            :max-width="150"
            alt="Payfoot logo"
            contain
            class="cursor-pointer"
          />
        </router-link>
      </header>

      <v-main class="flex-grow-1 pt-5">
        <router-view />
      </v-main>

      <footer>
        <AppFooter />
      </footer>
    </v-container>
  </v-app>
</template>

<script>
import AppFooter from "@/components/layouts/app/AppFooter.vue";

export default {
  components: { AppFooter },
};
</script>

<style lang="scss">
body {
  background-color: $ocean;
}
</style>

<style lang="scss" scoped>
.default-layout {
  &.has-background {
    background: url("~@/assets/images/auth/background.png") no-repeat center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  &:not(.has-background) {
    background-color: $ocean;
  }

  .page {
    height: 100%;
  }
}
</style>
